import React, {
	useEffect,
	useState,
	useRef,
	useCallback,
} from 'react';
import {
	trackerItemVO,
} from '../../model/ItemModel';
import {
	GetAllTracker,
	GetAllTrackerTrim,
} from '../../services/Item/ItemServicesGet';
import { useNavigate } from 'react-router-dom';
import styles from './FeedList.module.css';
import stylesList from '../Style/List.module.css';
import { useTranslation } from 'react-i18next';
import 'react-dater/dist/index.css';
import Tooltip from '@mui/material/Tooltip';
import WorkSpaceItemListFileComponentImage from '../WorkSpaceItem/WorkSpaceItemListFileComponentImage';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
	TranslatorS,
} from '../../components/I18n';
import WaitPage from "../ErroPage/WaitPage";
import { formatDateString } from "../../functions/dateToString"

function useVisibility(
	cb: (isVisible: boolean) => void,
	deps: React.DependencyList
): (node: any) => void {
	const intersectionObserver = useRef<IntersectionObserver | null>(null);
	return useCallback((node) => {
		if (intersectionObserver.current) {
			intersectionObserver.current.disconnect();
		}

		intersectionObserver.current = new IntersectionObserver(([entry]) => {
			cb(entry.isIntersecting);
		});

		if (node) intersectionObserver.current.observe(node);
	}, deps);
}

interface FeedProps {
	tracker: trackerItemVO;
	ref?: React.Ref<HTMLLIElement>;
}

interface ListFeed {
	nameOrg: string;
	begin?: string;
	end?: string;
}

const FeedListComp: React.FC<ListFeed> = ({ nameOrg, begin, end }) => {
	const [tracker, setracker] = useState<trackerItemVO[]>([]);
	const navegate = useNavigate();
	const limit: number = 50;
	const [wait, setWait] = useState<boolean>(false);
	const [popupErrorFromCalendarRequest, setPopupErrorFromCalendarRequest] =
		useState<boolean>(false);
	const [
		popupWarningEmpytFromCalendarRequest,
		setPopupWarningEmpytFromCalendarRequest,
	] = useState<boolean>(false);
	const [messageErrorCalendarRequest, setMessageErrorCalendarRequest] =
		useState<string>('');

	let [orderDesc, setOrderDesc] = useState<boolean>(false);
	let [skip, setSkip] = useState<number>(0);
	let [FeedMax, setFeedMax] = useState<boolean>(true);

	const { t, i18n } = useTranslation();    

	const Feed: React.ForwardRefExoticComponent<FeedProps> = React.forwardRef(
		({ tracker }, ref: React.Ref<HTMLLIElement>) => {

			return (
				<li
					className={`${stylesList.itemContent} ${stylesList.row} `}
					key={tracker.id}
					ref={ref}
				>
					<div className={styles.fatherList}>
						<div>
							<WorkSpaceItemListFileComponentImage
								extension={tracker.itemExtension} isFolder={tracker.itemExtension == undefined}
							/>
						</div>

						<Tooltip title={tracker.nameItem!} disableInteractive>
							<div className={styles.NameItemList}>{tracker.nameItem}</div>
						</Tooltip>

						<Tooltip title={tracker.message!} disableInteractive>
							<div className={styles.Action}>
								{tracker.message}
							</div>
						</Tooltip>

						{tracker.revision != undefined ? (
							<Tooltip title={tracker.revision!} disableInteractive>
								<div className={styles.RevisionList}>{tracker.revision}</div>
							</Tooltip>
						) : (
							<Tooltip title={tracker.idRevision!} disableInteractive>
								<div
									className={styles.RevisionList}
								>
									---
								</div>
							</Tooltip>
						)}

						<Tooltip title={tracker.user!} disableInteractive>
							<div className={styles.NameUserList}>{tracker.user}</div>
						</Tooltip>

						<div className={styles.IpList}>{tracker.ip}</div>
						<div className={styles.DateEnd}>
                            {formatDateString(tracker.dateCreateTracker)}
						</div>
					</div>
				</li>
			);
		}
	);

	const lastBook = useVisibility(
		(visible) => {
			if (visible) {

				if (begin && end) {
					if (begin > end) {
						setMessageErrorCalendarRequest(
							TranslatorS({ path: 'ErrorDate.Begin' })
						);
						setPopupErrorFromCalendarRequest(true);
					} else if (end < begin) {
						setMessageErrorCalendarRequest(
							TranslatorS({ path: 'ErrorDate.End' })
						);
						setPopupErrorFromCalendarRequest(true);
					} else {
                        setWait(true);
						GetAllTrackerTrim(nameOrg, begin, end, skip, limit).then((Feed) => {
							if (Feed.length === 0) {
								setMessageErrorCalendarRequest(
									t('ReturnEmptyTracker.MessageSnack')
								);
								setPopupWarningEmpytFromCalendarRequest(true);
							}
							if (Feed.length < limit) {
								setFeedMax(false);
							}
							setSkip(skip + Feed.length);
							setracker([...tracker, ...Feed]);
						}).catch((error: any) => {
							setMessageErrorCalendarRequest(error.response.data);
						})
                        .finally(() => setWait(false));
					}
				
				} else if (begin! > end!) {
					setMessageErrorCalendarRequest(
						TranslatorS({ path: 'ErrorDate.Begin' })
					);
					setPopupErrorFromCalendarRequest(true);
				} else if (end! < begin!) {
					setMessageErrorCalendarRequest(
						TranslatorS({ path: 'ErrorDate.End' })
					);
					setPopupErrorFromCalendarRequest(true);
				} else {
                    setWait(true);
					GetAllTrackerTrim(nameOrg, begin!, end!, skip, limit).then((Feed) => {
						if (Feed.length === 0) {
							setMessageErrorCalendarRequest(
								t('ReturnEmptyTracker.MessageSnack')
							);
							setPopupWarningEmpytFromCalendarRequest(true);
						}
						if (Feed.length < limit) {
							setFeedMax(false);
						}
						setSkip(skip + Feed.length);
						setracker([...tracker, ...Feed]);
					}).catch((error: any) => {
						setMessageErrorCalendarRequest(error.response.data);
					})
                    .finally(() => {
                        setWait(false);
                    });
				}
			}
		},
		[nameOrg, skip, tracker]
	);

	useEffect(() => {
		if (begin && end) {
			if (begin > end) {
				setMessageErrorCalendarRequest(
					TranslatorS({ path: 'ErrorDate.Begin' })
				);
				setPopupErrorFromCalendarRequest(true);
			} else if (end < begin) {
				setMessageErrorCalendarRequest(
					TranslatorS({ path: 'ErrorDate.End' })
				);
				setPopupErrorFromCalendarRequest(true);
			} else {
                setWait(true);
				GetAllTracker(nameOrg, begin, end)
                .then((Feed) => {
					if (Feed.length === 0) {
						setMessageErrorCalendarRequest(
							t('ReturnEmptyTracker.MessageSnack')
						);
						setPopupWarningEmpytFromCalendarRequest(true);
					}
					if (Feed.length < limit) {
						setFeedMax(false);
					}
					setSkip(skip + Feed.length);
					setracker([...tracker, ...Feed]);
				})
                .finally(() => {
                    setWait(false);
                });
			}
		} else if (begin! > end!) {
			setMessageErrorCalendarRequest(
				TranslatorS({ path: 'ErrorDate.Begin' })
			);
			setPopupErrorFromCalendarRequest(true);
		} else if (end! < begin!) {
			setMessageErrorCalendarRequest(TranslatorS({ path: 'ErrorDate.End' }));
			setPopupErrorFromCalendarRequest(true);
		} else {
            setWait(true);
			GetAllTrackerTrim(nameOrg, begin!, end!, skip, limit)
            .then((Feed) => {
				if (Feed.length === 0) {
					setMessageErrorCalendarRequest(
						t('ReturnEmptyTracker.MessageSnack')
					);
					setPopupWarningEmpytFromCalendarRequest(true);
				}
				if (Feed.length < limit) {
					setFeedMax(false);
				}
				setSkip(skip + Feed.length);
				setracker([...tracker, ...Feed]);
			})
            .finally(() => {
                setWait(false);
            });
		}
		
	}, [nameOrg, begin, end, orderDesc]);

	const handleCloseSnack = (event: any, reason: any) => {
		closePopUpOk();
	};

	const handleClosePO = (event: any) => {
		closePopUpOk();
	};

	function closePopUpOk() {
		setPopupErrorFromCalendarRequest(false);
		setPopupWarningEmpytFromCalendarRequest(false);
	}

	return (
		<div>
            {wait ? <WaitPage/> : (
                <ul className={stylesList.ulContent}>
                    <li className={`${stylesList.itemContent} ${stylesList.title}`} key={"title"}>
                        <div className={styles.icons}></div>
                        <div className={styles.Name}>{t('Nome.message')}</div>{' '}
                        <div className={styles.Action}>
                            {t('AcaoExecutada.message')}
                        </div>
                        <div className={styles.Revision}>{t('Description.message')}</div>
                        <div className={styles.User}>{t('Usuario.message')}</div>
                        <div className={styles.Ip}>Ip</div>
                        <div className={styles.DateEnd}>{t('DateAction.message')}</div>
                    </li>
                    {tracker.map((trackerC, index) => (
                        <Feed
                            key={trackerC.id}
                            tracker={trackerC}
                            ref={
                                tracker[tracker.length - 1].id === trackerC.id && FeedMax
                                    ? lastBook
                                    : null
                            }
                        />
                    ))}
                </ul>
            )}
			<Snackbar
				open={popupErrorFromCalendarRequest}
				autoHideDuration={6000}
				message="Archived"
				onClose={handleCloseSnack}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				sx={{ bottom: { xs: 50, sm: 20 } }}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					sx={{ width: '100%' }}
					severity="error"
					onClose={handleClosePO}
				>
					{messageErrorCalendarRequest}
				</MuiAlert>
			</Snackbar>
			<Snackbar
				open={popupWarningEmpytFromCalendarRequest}
				autoHideDuration={6000}
				message="Archived"
				onClose={handleCloseSnack}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				sx={{ bottom: { xs: 50, sm: 20 } }}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					sx={{ width: '100%' }}
					severity="warning"
					onClose={handleClosePO}
				>
					{messageErrorCalendarRequest}
				</MuiAlert>
			</Snackbar>
		</div>
	);
};
export default FeedListComp;
