import { Shimmer, ShimmerElementType, ShimmerElementsGroup } from '@fluentui/react';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useUpload } from "../../context/UploadProvider/useUpload";
import { D4SignStatus, FolderInformation, ItemValidationRule, Temporality, WorkSpaceUserName } from "../../model/ItemModel";
import {
  GetItemByTag,
  GetItemByTagAndFolder,
  GetItensFromWorkspaceIdSearch,
  GetItensFromWorkspaceIdSearchOnlyFolder,
  GetItensFromWorkspaceIdSearchOnlyRoot,
  GetOneItem,
  GetWorkSpaceItemTrim,
  GetWorkSpaceItemTrimRecursive
} from "../../services/Item/ItemServicesGet";
import { GetOneWorkSpacePermission } from "../../services/WorkSpaceServices";
import WorkSpaceItemListFileComponent from '../WorkSpaceItem/WorkSpaceItemListFileCompose/WorkSpaceItemListFileComponent';
import { CopyFolderStructureDrawerParameters } from "./PanelItens/CopyFolderStructureDrawer";
import { NewFolderConfig } from './PanelItens/CreateFolderComp';
import { EditFolderOpen } from './PanelItens/EditFolder';
import { RenameConfig } from './PanelItens/RenameComp';
import { ShareFileConfig } from "./PanelItens/ShareFile/ShareFileComp";
import { TemporalityConfig } from "./PanelItens/TemporalityDocument";
import { GetDragPermission } from './PermissionRules/DragPermission';
import { useInputLayout } from "./ResizeStyles/WorkSpaceItemListStyles/InputLayout";
import { Filepload, Manyfilepload, ManyfileploadDirectoryPicker } from "./UploadFunction";
import ChoicerViwerModal from "./Viwer/ChoicerViwerModal";
import styles from "./WorkSpaceItemList.module.css";
import { WorkItemProps } from "./WorkSpaceItemListFileCompose/WorkItemProps/WorkItemProps";
import WorkSpaceItemListFileHeader from "./WorkSpaceItemListFileHeader";
import WorkSpaceItemListFileTitle from './WorkSpaceItemListFileTitle';

import LoaderComp from '../../components/Loader/LoaderComp';
import { MenuConfig } from "../../components/Menu/MenuConfig";
import { GridContext } from '../../context/GridProvider/GridContext';
import { GridContextType } from '../../context/GridProvider/GridContextTypes';
import { useProfile } from "../../context/ProfileProvider/useProfile";
import { handleAllowed } from "../../functions/handleAllowed";
import { TagName } from "../../model/Tags";
import { FlowProps } from "../WorkSpaceItem/FlowFolder/WorkspaceItemFlowComponent";
import WorkSpaceItemListFileShrink from "./WorkSpaceItemListFileCompose/WorkSpaceItemListFileShrink";
import WorkSpaceItemListFileUpload from "./WorkSpaceItemListFileCompose/WorkSpaceItemListFileUpload";
import { GridColumn, GridColumnDeleted, ReturnColumnSizeStandard, getDefault, name } from "./WorkSpaceItemListStyle/ColumnStandard";
import { GetColumnParameters, SetColumnParameters } from "./WorkSpaceItemListStyle/WorkspaceColumnSave";

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    webkitdirectory?: string;
    directory?: string;
  }
}

interface CustomWindow extends Window {
  showDirectoryPicker?: () => Promise<any>;
}

declare const window: CustomWindow;

function useVisibility(
    cb: (isVisible: boolean) => void,
    deps: React.DependencyList
  ): (node: any) => void {
    const intersectionObserver = useRef<IntersectionObserver | null>(null);
    return useCallback((node) => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }

      intersectionObserver.current = new IntersectionObserver(([entry]) => {
        cb(entry.isIntersecting);
      });

      if (node) intersectionObserver.current.observe(node);
    }, deps);
  }

export interface WorkSpaceItemListFileConfigSearch {
  idWorkspace: string;
  idFolder: string | undefined;
  search:string | undefined;
  folder:string;
  idTag:string
}

interface WorkSpaceItemListFileConfig {
    searshParams: WorkSpaceItemListFileConfigSearch;
    nameOrg: string;
    setFolderConfig: (newFolder:NewFolderConfig) => void;
    setFolderExternal: (folderId:string | undefined, folderName:string | undefined) => void;
    deleteItem: (itemId:string) => void;
    onClickRevision: (itemId:string) => void;
    onclikMoveOpen: (itemId:string) => void;
    setShareFileConfig: (shareFileConfig:ShareFileConfig) => void;
    editFolderOpen: (editFolderOpen:EditFolderOpen) => void;
    rename: (renameConfig:RenameConfig) => void;
    temporalityDocument:(temporalityConfig: TemporalityConfig) => void;
    currentFolder:FolderInformation | undefined;
    boxLayout: boolean;
    objectReference: any;
    CheckboxItemList: any;
    setPopupErroMessage: (set: boolean, message: string) => void;
    setPopupOkMessage: (set: boolean, message: string) => void;
    onClickFlow: (flowProps : FlowProps) => void;
    onDrop: (
      event: React.DragEvent<HTMLDivElement>,
      key: string | undefined,
      endFunction: () => void,
    ) => void;
    setCopyFolderStructureDrawerParametersExternal: (copyFolderStructureDrawerParameters: CopyFolderStructureDrawerParameters) => void;
    isEditorCannotSeeRevision?: boolean;
  }

  export interface filesSelected {
    name: string;
    extension: string;
    id: string;
    img?: JSX.Element;
    isFolder: boolean;
    isRelease: boolean;
    validationRule: ItemValidationRule | undefined;
  }

  export const transformedExtensions = (img: string) => {
    for (let i = 0; i < 3; i++) {

      const transformArray = img?.split('').reverse()
      const returnedItem: string[] = [];
      
      for (let i = 0; i <= transformArray!.length; i++) {

        const element = transformArray![i];

        if (element !== "/") {
          returnedItem.push(element);
        } else if (element === "/") {
          break;
        }

      }

      return returnedItem.reverse().join('').toLowerCase();
    }
  }
   
  const WorkSpaceItemListFile: React.ForwardRefExoticComponent<WorkSpaceItemListFileConfig> = React.forwardRef(({
    searshParams,
    nameOrg,
    setFolderConfig,
    setFolderExternal,
    deleteItem,
    onClickRevision,
    onclikMoveOpen,
    editFolderOpen,
    setShareFileConfig,
    rename,
    temporalityDocument,
    currentFolder,
    boxLayout,
    objectReference,
    CheckboxItemList,
    setPopupErroMessage,
    setPopupOkMessage,
    onClickFlow,
    onDrop,
    setCopyFolderStructureDrawerParametersExternal,
    isEditorCannotSeeRevision
  }, ref) => {

    const [waitWorkSpaceItem, setWaitWorkSpaceItem] = useState<boolean>(false);
    const [WorkSpaceItem, setWork] = useState<WorkSpaceUserName[]>([]);
    const [newerUploadItems, setNewerUploadItems] = useState<WorkSpaceUserName[]>([]);
    const limit = 50;
    const { t, i18n } = useTranslation();
    let [workspaceMax, setWorkspaceMax] = useState<boolean>(true)
    let [orderDesc, setOrderDesc] = useState<boolean>(false);
    let [skip, setSkip] = useState<number>(0);
    let [WorkMax, setWorkMax] = useState<boolean>(true);
    let [refresh, setRefresh] = useState<boolean>(false);
    const [popupOk, setPopupOk] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [alertColor, setAlertColor] = useState<AlertColor>("error");
    const upload = useUpload();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [nameWorkspace, setNameWorkspace] = useState<string|undefined>(undefined);
    const refHtml = React.useRef<HTMLUListElement | null>(null);
    
    const [selectedCheckbox, setSelectedCheckbox] = useState<filesSelected[]>([]);

    const [itemIdConst, setItemIdConst] = useState<string | undefined>(undefined)
    const [isOpenConst, setIsOpenConst] = useState<boolean>(false)
    const [nameConst, setNameConst ] = useState<string | undefined>(undefined)
    const [extensionConst, setExtensionConst] = useState<string | undefined>(undefined)
    const [menuConfigConst, setMenuConfigConst] = useState<MenuConfig | undefined>(undefined)
    const [currentBatchStatus, setCurrentBatchStatus] = useState<"DRAFT" | "RELEASE" | undefined>(undefined);
    const [currentParameters, setCurrentParameters] = useState<WorkSpaceItemListFileConfigSearch | undefined>(undefined);
    const location = useLocation();
    const [batchWaiter, setBatchWaiter] = useState<boolean>(false);
    const [isEditingItem, setIsEditingItem] = useState(false);

    const { columns, setColumnsEx, persistColumn } = React.useContext(GridContext) as GridContextType;

    const { id } = useProfile();
    
    function getCurrnteSize() : GridColumn[] | GridColumnDeleted[]
    {
      let gridLocalStorage:GridColumn[] | undefined = GetColumnParameters();
      if(gridLocalStorage == undefined || gridLocalStorage.length == 0)
      {
        let gridLocalStorageTotal = ReturnColumnSizeStandard();
        setColumnsEx(gridLocalStorageTotal); 
        SetColumnParameters(columns);
        return gridLocalStorageTotal;
      }
      else
      {
        if(gridLocalStorage.some(x => x.name == name) == false)
        {
          gridLocalStorage.push({
            name: name,
            length: getDefault(name)
          })
        }
        return gridLocalStorage;
      }
    }

    function updateNameDescriptionByIndex(index:number, name:string, isUpload: boolean, description?:string): void
    {
      if (isUpload)
      {
        newerUploadItems[index].name = name;
        newerUploadItems[index].description = description;
        setNewerUploadItems(newerUploadItems);
      } else
      {
        WorkSpaceItem[index].name = name;
        WorkSpaceItem[index].description = description;
        setWork(WorkSpaceItem);
      }
    }

    function setWorkspace(workSpaceUserName:WorkSpaceUserName, index:number, isUpload: boolean) 
    {
      if (isUpload)
      {
        if(WorkSpaceItem.length > index)
        {
          let array = newerUploadItems;
          array[index] = workSpaceUserName;
          setNewerUploadItems(array);
        }
      }
      else
      {
        if(WorkSpaceItem.length > index)
        {
          const newArray = WorkSpaceItem
            .map((item, indexOfArray) => indexOfArray == index ? workSpaceUserName : item);
          setWork(newArray);
        }
      }
    }

    function updateArchivenByIndex(index:number, isArchiving: boolean, isUpload: boolean) {

      if (isUpload)
      {
        if(newerUploadItems.length > index)
        {
          let array = newerUploadItems;
          array[index].archiving = isArchiving;
          setNewerUploadItems(array);
        }
      }
      else
      {
        if(WorkSpaceItem.length > index) {
          let array = WorkSpaceItem;
          array[index].archiving = isArchiving;
          setWork(array);
        }
      }
    }

    function setIsD4SignSignedConst(d4SignStatus:D4SignStatus | undefined,isD4SignSigned: boolean | undefined, index: number, isUpload: boolean) {
      if (isUpload)
      {
        if(newerUploadItems.length > index)
        {
          let array = newerUploadItems;
          array[index].d4SignStatus = d4SignStatus;
          array[index].isD4SignSigned = isD4SignSigned;
          setNewerUploadItems(array);
        }
      }
      else
      {
        if(WorkSpaceItem.length > index) {
          let array = WorkSpaceItem;
          array[index].d4SignStatus = d4SignStatus;
          array[index].isD4SignSigned = isD4SignSigned;
          setWork(array);
        }
      }
    }


    function setTemporalityAndExpired(index:number, isUpload: boolean, newTemporality?:Temporality, expired?: boolean, archiving?: boolean)
    {
      if (isUpload)
      {
        if(newerUploadItems.length > index) {
          let array = newerUploadItems;
          array[index].itemTemporality = newTemporality;
          array[index].expired = expired;
          array[index].archiving = archiving;
          setNewerUploadItems(array);
        }
      } 
      else
      {
        if(WorkSpaceItem.length > index) {
          let array = WorkSpaceItem;
          array[index].itemTemporality = newTemporality;
          array[index].expired = expired;
          array[index].archiving = archiving;
          setWork(array);
        }
      }
    }

    function setIsReleaze(isRelease: boolean, index: number, isUpload: boolean)
    {
      if (isUpload)
      {
        if(newerUploadItems.length > index) {
          let array = newerUploadItems;
          array[index].isRelease = isRelease;
          setNewerUploadItems(array);
        }
      }
      else
      {
        if(WorkSpaceItem.length > index)
        {
          let array = WorkSpaceItem;
          array[index].isRelease = isRelease;
          setWork(array);
        }
      }
    }
    
    function setValidationRule(itemValidationRule: ItemValidationRule | undefined, indexOfArray: number, isUpload: boolean) {
      if (isUpload)
      {
        if (newerUploadItems.length > indexOfArray) {
          let array = newerUploadItems;
          array[indexOfArray].validationRule = itemValidationRule
          setNewerUploadItems(array);
        }
      }
      else
      {
        if (WorkSpaceItem.length > indexOfArray) {
          const newArray = WorkSpaceItem;
          newArray[indexOfArray].validationRule = itemValidationRule
          setWork([...newArray]);
        }
      }
    }
    
    
    function updateTagName(index:number, tagName:TagName[], isUpload: boolean)
    {
      if (isUpload)
      {
        let array = newerUploadItems;
        array[index].tagName = tagName;
        setNewerUploadItems(array);
      }
      else
      {
        let array = WorkSpaceItem;
        array[index].tagName = tagName;
        setWork(array);
      }
    }

    function openDetailModal(index: number, setOpen: (bool: boolean) => void)
    { 
      setOpen(true);
    }
    
    function getUpload()
    {
      return upload;
    }

    const showFileChooser = () => document.getElementById("fileInput")!.click();

    async function showFolderChooser (){
      if(window.showDirectoryPicker != undefined)
      try {
        const dirHandle = await window.showDirectoryPicker();
        await ManyfileploadDirectoryPicker(dirHandle, searshParams.idWorkspace, searshParams.idFolder, getUpload);
      } catch (error) {
        console.error('Erro ao fazer upload da pasta:', error);
      }
    }
      
    const refreshFunction = () => {
      setRefresh(true);
      setNewerUploadItems([]);
    }

    const handleClose = (event:any, reason:any) => {
      closePopUpOk();
    }

    const handleClosePO = (event:any) => {
      closePopUpOk();
    }

    function closePopUpOk() {
      setPopupOk(false);
    }

    function setPopupOkExternalError(set:boolean, message:string) {
      setAlertColor('error');
      setErrorMessage(message);
      setPopupOk(set);
    }

    function setPopupOkExternalOk(set:boolean, message:string) {
      setAlertColor('success');
      setErrorMessage(message);
      setPopupOk(set);
    }

    const onChangeFileChooser = (e: ChangeEvent<HTMLInputElement>) => {

      const files: FileList | null = e.target.files;
      if(files != null)
      {
        Filepload(files, searshParams.idWorkspace, searshParams.idFolder, getUpload);
      }
    };

    const preventDefaultBehaviour = (event: React.DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
    };
    
    const onDropFile = (event: React.DragEvent<HTMLDivElement>) => {
      preventDefaultBehaviour(event);
      const items = event.dataTransfer.items;
      Manyfilepload(items, searshParams.idWorkspace, searshParams.idFolder, getUpload);
    };  
    
    const onDragEnterAndOver = (event: React.DragEvent<HTMLDivElement>) => {
      preventDefaultBehaviour(event);
    };

    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
      preventDefaultBehaviour(event);
    };

    useEffect(() => {
     if(refresh == true)
     {
        resetForceFiles(searshParams);
        setRefresh(false);
        setNewerUploadItems([]);
     }

    }, [refresh, resetFiles]);

		const chengeAllChackbox = (set:boolean) => {	
      let i:number = 0;
      let array:filesSelected[] = [];
      for(i = 0;i<myRefs.current.length;i++)
      {
        myRefs.current[i].setIsCheckedExternal(set);
        if(set == true)
        {
          array.push(myRefs.current[i].getFilesSelected());
        }
      }

      setSelectedCheckbox(array);
		}

    const myRefs = useRef<any[]>([]);

    useEffect(() => {
      myRefs.current = myRefs.current.slice(0, WorkSpaceItem.length);
    }, [WorkSpaceItem]);

   useEffect(() => {
      if (upload.itemId != undefined)
      {
        GetOneItem(upload.itemId).then(item => {
          if(canAddFile(item))
          {
            console.log(item);
            addItem(item);
          } 
        })
      }
   }, [upload.itemId])


  function canInsertItem(item: WorkSpaceUserName) {
    let canAdd: boolean = true;

    let itemFoundedAtArray = newerUploadItems.filter(itemInArray => {
      return itemInArray.extension == item.extension && itemInArray.name == item.name;
    });

    if (itemFoundedAtArray.length <= 0) {
      itemFoundedAtArray = WorkSpaceItem.filter(itemInArray => {
        return itemInArray.extension == item.extension && itemInArray.name == item.name;
      });
    }

    if (itemFoundedAtArray.length > 1) {
      canAdd = false;
    }

    return canAdd;
  }
   
   function canAddFile(item: WorkSpaceUserName): boolean
   {
      if (item.fatherId == undefined && currentFolder?.folderId == undefined) {
        return true;
      }

      if (currentFolder != undefined && item.fatherId != undefined && item.fatherId == currentFolder.folderId)
      {
          return true;
      }
      return false;
   }

   function addItem(item: WorkSpaceUserName): void
   {
    var listFoundInWorkspace: WorkSpaceUserName | undefined = WorkSpaceItem.find(x => x.id == item.id);
    var listFoundInUploads: WorkSpaceUserName | undefined = newerUploadItems.find(x => x.id == item.id);

    if(listFoundInWorkspace == undefined && listFoundInUploads == undefined) 
    {
      const updateWorks = [...newerUploadItems];
      updateWorks.push(item);
      setNewerUploadItems(updateWorks);
    }
    else
    {
      if (listFoundInWorkspace != undefined) {
        const index = WorkSpaceItem.indexOf(listFoundInWorkspace);
        WorkSpaceItem[index] = item;
        setWork(WorkSpaceItem);
      } else {
        console.log(listFoundInUploads)
        const index = newerUploadItems.indexOf(listFoundInUploads!);
        newerUploadItems[index] = item;
        setNewerUploadItems(newerUploadItems);
      }
    }
   }

    const getWorkspaceWhenVisible = () => {
      if (searshParams.search == null  || searshParams.search == undefined || searshParams.search == "" ) {
        if(searshParams.idFolder==undefined||searshParams.idFolder==""||searshParams.idFolder==null)
        {
          if(searshParams.idTag != ""){
            GetItemByTag(searshParams.idTag,0,limit)
            .then((newWork) => {
              if (newWork.length < limit) {
                setWorkMax(false);
              }
              setSkip(newWork.length);
              setWork(newWork);
              setWaitWorkSpaceItem(false);
            })
          }
          else{
            GetWorkSpaceItemTrim(searshParams.idWorkspace,skip, limit, orderDesc).then((newWork) => {
              if (newWork.length < limit) {
                setWorkMax(false);
              }
              setSkip(skip + newWork.length);
              setWork([...WorkSpaceItem, ...newWork]);
            });
          }        
        }
        else
        {
          if(searshParams.idTag != ""){
            GetItemByTagAndFolder(searshParams.idTag,searshParams.idFolder,0,limit)
            .then((newWork) => {
              if (newWork.length < limit) {
                setWorkMax(false);
              }
              setSkip(newWork.length);
              setWork(newWork);
              setWaitWorkSpaceItem(false);
            })
          }
          else{
            GetWorkSpaceItemTrimRecursive(searshParams.idWorkspace,searshParams.idFolder,skip, limit, orderDesc).then((newWork) => {
              if (newWork.length < limit) {
                setWorkMax(false);  
              }
              setSkip(skip + newWork.length);
              setWork([...WorkSpaceItem, ...newWork]);
            });
          }
        }
      }
      else
      {
         if(searshParams.folder == "true"){
          if(searshParams.idFolder != undefined)
          {
            if(searshParams.idTag != null){
              GetItemByTagAndFolder(searshParams.idTag,searshParams.idFolder,0,limit)
              .then((newWork) => {               
                if (newWork.length < limit) {
                  setWorkMax(false);
                }
                setSkip(newWork.length);
                setWork(newWork);
                setWaitWorkSpaceItem(false);
              })
            }
            else{
              GetItensFromWorkspaceIdSearchOnlyFolder(searshParams.idWorkspace,searshParams.idFolder, skip, limit, searshParams.search.replace("#","%23")).then((newWork) => {
                if (newWork.length < limit) {
                  setWorkMax(false);
                }
                setSkip(newWork.length);
                setWork(newWork);
                setWaitWorkSpaceItem(false);
              });
            }
          }
          else
          {
            GetItensFromWorkspaceIdSearchOnlyRoot(searshParams.idWorkspace, skip, limit, searshParams.search.replace("#","%23")).then((newWork) => {
              if (newWork.length < limit) {
                setWorkMax(false);
              }
              setSkip(newWork.length);
              setWork(newWork);
              setWaitWorkSpaceItem(false);
            });
          }
        }
        else{
          GetItensFromWorkspaceIdSearch(searshParams.idWorkspace, skip, limit, searshParams.search.replace("#","%23")).then((newWork) => {
            if(newWork.length < limit) {
              setWorkspaceMax (false);
            }
            setSkip(skip + newWork.length);
            setWork([...WorkSpaceItem, ...newWork]);
          });
        }
      }
    }

    const lastBook = useVisibility(
      (visible) => {
        if (visible) {
          getWorkspaceWhenVisible()
        }
      },[skip, WorkSpaceItem]
    );

      useEffect(() => {
        resetFiles(searshParams);
      }, [searshParams, orderDesc]);

      useEffect(() => {
        persistColumn();
      }, [columns]);

      function resetFiles(searshParams: WorkSpaceItemListFileConfigSearch)
      {
        setColumnsEx(getCurrnteSize());
        persistColumn(); 
        

        if(currentParameters == undefined)
        {
          setCurrentParameters(searshParams);
        }
        else
        {
          if(
            currentParameters.idWorkspace == searshParams.idWorkspace &&
            currentParameters.idFolder == searshParams.idFolder &&
            (searshParams.search =="" || searshParams.search  == null || searshParams.search  == undefined) &&
            (currentParameters.search =="" || currentParameters.search  == null || currentParameters.search  == undefined) &&
            (searshParams.idTag =="" || searshParams.idTag  == null || searshParams.idTag  == undefined) &&
            (currentParameters.idTag =="" || currentParameters.idTag  == null || currentParameters.idTag  == undefined) 
          )
          {
            setCurrentParameters(searshParams);
            return;
          }
        }

        resetForceFiles(searshParams);
      }

      function resetForceFiles(searshParams: WorkSpaceItemListFileConfigSearch)
      {
        setCurrentParameters(searshParams);

        if(searshParams.search != "" || searshParams.search != undefined || searshParams.idTag != "" || searshParams.idTag != undefined){
          setWaitWorkSpaceItem(true);
        }
        
        setWork([]);
        setWorkMax(true);
        setSelectedCheckbox([]);
        setNewerUploadItems([]);

        if(searshParams.search =="" || searshParams.search  == null || searshParams.search  == undefined)
        {
          if(searshParams.idFolder==undefined || searshParams.idFolder=="" || searshParams.idFolder== null)
          {
            if(searshParams.idTag != ""){
              GetItemByTag(searshParams.idTag,0,limit)
              .then((newWork) => {
                if (newWork.length < limit) {
                  setWorkMax(false);
                }
                setSkip(newWork.length);
                setWork(newWork);
                setWaitWorkSpaceItem(false);
              })
            }
            else{
              GetWorkSpaceItemTrim(searshParams.idWorkspace,0, limit, orderDesc).then((newWork) => {
                if (newWork.length < limit) {
                  setWorkMax(false);
                }
                setSkip(newWork.length);
                setWork(newWork);
                setWaitWorkSpaceItem(false);
              });
            }
          }
          else
          {
            if(searshParams.idTag != ""){
              if(searshParams.folder == "true"){
                GetItemByTagAndFolder(searshParams.idTag,searshParams.idFolder,0,limit)
                .then((newWork) => {                
                  if (newWork.length < limit) {
                    setWorkMax(false);
                  }
                  setSkip(newWork.length);
                  setWork(newWork);
                  setWaitWorkSpaceItem(false);
                })
              }   
              else{
                GetItemByTag(searshParams.idTag,0,limit)
                .then((newWork) => {                
                  if (newWork.length < limit) {
                    setWorkMax(false);
                  }
                  setSkip(newWork.length);
                  setWork(newWork);
                  setWaitWorkSpaceItem(false);
                })
              }           
            }
            else{
              GetWorkSpaceItemTrimRecursive(searshParams.idWorkspace,searshParams.idFolder,0, limit, orderDesc).then((newWork) => {
                if (newWork.length < limit) {
                  setWorkMax(false);
                }
                setSkip(newWork.length);
                setWork(newWork);
                setWaitWorkSpaceItem(false);
              });
            }
          }
        }
        else
        {          
           if(searshParams.folder == "true"){
            if(searshParams.idFolder != undefined)
            {
              if(searshParams.idTag != null){
                GetItemByTagAndFolder(searshParams.idTag,searshParams.idFolder,0,limit)
                .then((newWork) => {
                  if (newWork.length < limit) {
                    setWorkMax(false);
                  }
                  setSkip(newWork.length);
                  setWork(newWork);
                  setWaitWorkSpaceItem(false);
                })
              }
              else{
                GetItensFromWorkspaceIdSearchOnlyFolder(searshParams.idWorkspace,searshParams.idFolder, 0, limit, searshParams.search.replace("#","%23")).then((newWork) => {
                  if (newWork.length < limit) {
                    setWorkMax(false);
                  }
                  setSkip(newWork.length);
                  setWork(newWork);
                  setWaitWorkSpaceItem(false);
                });
              }
            }
            else
            {              
              GetItensFromWorkspaceIdSearchOnlyRoot(searshParams.idWorkspace, 0, limit, searshParams.search.replace("#","%23")).then((newWork) => {
                if (newWork.length < limit) {
                  setWorkMax(false);
                }
                setSkip(newWork.length);
                setWork(newWork);
                setWaitWorkSpaceItem(false);
              });
            }
          }
          else{            
            GetItensFromWorkspaceIdSearch(searshParams.idWorkspace, 0, limit, searshParams.search.replace("#","%23")).then((newWork) => {
              if (newWork.length < limit) {
                setWorkMax(false);
              }
              setSkip(newWork.length);
              setWork(newWork);
              setWaitWorkSpaceItem(false);
            });
          }       
        }

        GetOneWorkSpacePermission(searshParams.idWorkspace)
        .then((idWork) =>{
          if(idWork?.isAdmin != undefined)
          {
            setIsAdmin(idWork?.isAdmin);
          }

          setNameWorkspace(idWork?.name);
        });
      }

    const resizeUl = () => {
      const ul = refHtml.current

      if (!ul) {
        return 0
      }

      ul.style.cssText = `

      `

    }
    
    resizeUl()

    const handleChange = (
      filesSelectedHandled: filesSelected,
      checked:boolean,
    ) => {

      if (checked) {
        const nameOrIdAreNotEqual = !selectedCheckbox.some(el => {
          return el.id === filesSelectedHandled.id
        })

        if (selectedCheckbox.length > 0) {
          if (nameOrIdAreNotEqual) {
            setSelectedCheckbox([
              ...selectedCheckbox,
              filesSelectedHandled
            ])
          }
        } else {
          setSelectedCheckbox([
            ...selectedCheckbox,
            filesSelectedHandled
          ])
        }

      } else {
        setSelectedCheckbox(
          selectedCheckbox.filter(item => item.id !== filesSelectedHandled.id)
        )
      }
    }

    function handleMultiContainer(container: HTMLDivElement, fileContainer: HTMLDivElement, currentComponent:filesSelected | undefined) {
      
      let i = 0;
      for(i = 0; i < 3 && i < selectedCheckbox.length; i++)
      {
        let file = selectedCheckbox[i];
        container.appendChild(getDiv((file.isFolder || file.extension === undefined) ? `${file.name}` : `${file.name}.${file.extension}`, file.img));
      }

      if(selectedCheckbox.length < 2 && currentComponent != undefined)
      {
        container.appendChild(getDiv((currentComponent.isFolder || currentComponent.extension === undefined) ? `${currentComponent.name}` : `${currentComponent.name}.${currentComponent.extension}`, currentComponent.img));
      }

      if( (selectedCheckbox.length > 3 && currentComponent == undefined) ||  (selectedCheckbox.length > 2 && currentComponent != undefined))
      {
        container.appendChild(getDiv("...", undefined));
      }
    }

    function getDiv(text:string, img:JSX.Element|undefined )
    {
      let fileContainer = document.createElement("div");

        fileContainer.style.width = "100%";
        fileContainer.style.height = "100%";
        fileContainer.style.maxWidth = "200px";
        fileContainer.style.display = "flex";
        fileContainer.style.padding = "10px";
        fileContainer.style.flexDirection = "row";

        if(img)
        {
          const divElement = document.createElement('div');
          divElement.style.marginRight = "8px";
          const staticElement = renderToStaticMarkup(img)
          divElement.innerHTML = staticElement
          fileContainer.appendChild(divElement);

        }

        fileContainer.appendChild(document.createTextNode(text));
        return fileContainer;
    }

    function handleMultiDrag(ev: React.DragEvent<HTMLDivElement>, currentComponent: filesSelected): void {
      if (isEditingItem) {
        setErrorMessage(t("Drag.NotPossible"));
        setPopupOk(true);
        return;
      }

      if (!isEditingItem) {
          let container = document.createElement("div");
          container.style.display = "flex";
          container.style.width = "250px";
          container.style.height = "auto";
          container.style.color = "#1967d2";
          container.style.backgroundColor = "#e8f0fe";
          container.style.padding = "15px";
          container.style.borderRadius = "5px";
          container.style.border = "solid 1px";
          container.style.transform = "translate(-10000px, -10000px)";
          container.style.position = "absolute";  
          container.style.padding = "10px";
          container.style.flexDirection = "column";
    
          let fileContainer = document.createElement("div");
    
          fileContainer.style.width = "100%";
          fileContainer.style.height = "100%";
          fileContainer.style.display = "flex";
          fileContainer.style.justifyContent = "space-around";
          fileContainer.style.padding = "10px";
          fileContainer.style.flexDirection = "row";
    
          const nameOrIdAreNotEqual = !selectedCheckbox.some(el => {
            return el.id === currentComponent.id
          })
    
          if(nameOrIdAreNotEqual)
          {
            setSelectedCheckbox([
              ...selectedCheckbox,
              currentComponent
            ]);
          }
    
          handleMultiContainer(container, fileContainer, nameOrIdAreNotEqual?currentComponent:undefined);
    
          document.body.appendChild(container);
    
          let arrayId:string[] = selectedCheckbox.map(item => item.id!)
    
          if(arrayId.some(el => el == currentComponent.id) == false)
          {
            arrayId.push(currentComponent.id);
          }
    
          ev.dataTransfer.setData("idFile", JSON.stringify(arrayId));
    
          ev.dataTransfer.setDragImage(container, 0, 0);
      }
    }

    const useInput = useInputLayout();

  function batchRefresh(){
    resetForceFiles(searshParams);
  }

  const quantityFileSelected = () => {
    return selectedCheckbox.filter(item => !item.isFolder).length
  }

  const quantityAbleToReleaseSelected = () => {
    return selectedCheckbox.filter(item => item.isRelease == false && item.validationRule == undefined).length
  }

  const quantityAbleToDraftSelected = () => {
    return selectedCheckbox.filter(item => item.isRelease == true).length
  }

  const exibirViewerBoolean = (exibir: boolean) => {
    setIsOpenConst(exibir);
  };

  const exibirViewer = (idMongo: string, name: string, extension: string, menuConfig: MenuConfig) => {
    updateContent(idMongo, name, extension)
    setMenuConfigConst(menuConfig)
    exibirViewerBoolean(true);
  };
 
  const updateContent = (id: string, name: string, extension: string) => {
    setItemIdConst(id)
    setNameConst(name)
    setExtensionConst(extension)
  }


  const returnParamens = (index:number, workSpaceC:WorkSpaceUserName, currentFolderInternal: FolderInformation, isRef: boolean) => {
    const paramens:WorkItemProps = 
    {
      nameOrg:nameOrg,
      setIsReleaze: setIsReleaze,
      setValidationRule: setValidationRule,
      refViwer: (isRef && WorkSpaceItem[WorkSpaceItem.length - 1].id === workSpaceC.id && WorkMax) ? lastBook : null,
      deleteItem:deleteItem,
      setFolderExternal:setFolderExternal,
      idWorkspace:searshParams.idWorkspace,
      nameWorkspace:nameWorkspace!,

      setPopupError:setPopupOkExternalError,
      setPopupOk:setPopupOkExternalOk,
      onclickRevision:onClickRevision,
      onclikMoveOpen:onclikMoveOpen,
      layoutPendencies:false,
      refresh:refreshFunction,
      permissionType:currentFolder?.permissionType!,
      isAdmin:isAdmin,

      editFolderOpen:editFolderOpen,
      setShareFileConfig:setShareFileConfig,
      renameUpdate:rename,
      temporalityDocument:temporalityDocument,
      boxLayout:boxLayout,
      objectReference:objectReference,
      CheckboxItemList:CheckboxItemList,
      handleMultiDrag:handleMultiDrag,
      onClickFlow:onClickFlow,
      notificationEmail:workSpaceC.notificationEmail,
      handleChange:handleChange,
      onDrop:onDrop,
      idFolder:searshParams.idFolder,

      exibirViewer:exibirViewer,
      isCurrentViewerItem:workSpaceC.id === itemIdConst ? true : false,
      setMenuConfigConst: setMenuConfigConst,
      updateNameDescriptionByIndex:updateNameDescriptionByIndex,
      indexOfArray:index,
      setTemporalityAndExpiredExternal:setTemporalityAndExpired,

      openDetailModal:openDetailModal,
      selectedCheckbox:selectedCheckbox,
      currentBatchStatus:currentBatchStatus,
      batchWaiter:batchWaiter,
      batchRefresh: batchRefresh,
      updateTagName:updateTagName,
      workspaceItem:workSpaceC,
      setWorkspace: setWorkspace,
      updateArchivenByIndex:updateArchivenByIndex,
      setIsD4SignSignedConst: setIsD4SignSignedConst,
      setCopyFolderStructureDrawerParametersExternal:setCopyFolderStructureDrawerParametersExternal,
      currentFolder: currentFolder,
      setIsEditingItem: setIsEditingItem
    };

    return paramens;
  }

    return(
      <div  
        className={`${handleAllowed(false, "wsallowed", location) && `${styles.files} ${styles.background}`}`}
        onDragEnter={GetDragPermission(currentFolder)?(e) => onDragEnterAndOver(e):undefined}
        onDragLeave={GetDragPermission(currentFolder)?(e) => onDragLeave(e):undefined}
        onDragOver={GetDragPermission(currentFolder)?(e) => onDragEnterAndOver(e):undefined}
        onDrop={GetDragPermission(currentFolder)?(e) => onDropFile(e):undefined}
      >
        {currentFolder != undefined &&(
          <WorkSpaceItemListFileHeader
              nameOrg={nameOrg}
              idWorkspace={searshParams.idWorkspace}
              idMongoNewFolder={searshParams.idFolder}
              clickUpload={showFileChooser}
              clickUploadFolder={showFolderChooser}
              refresh={refreshFunction}
              setFolderConfig={setFolderConfig}
              typePermission={currentFolder.permissionType}
              isAdmin={isAdmin}
              nameFolder={currentFolder.folderName}
              path={currentFolder.pathList}
              onClickLinkMap={setFolderExternal}
              selectedCheckboxes={selectedCheckbox}
              setPopupError={setPopupErroMessage}
              setPopupOk={setPopupOkMessage}
              quantityFileSelected={quantityFileSelected}
              quantityAbleToReleaseSelected={quantityAbleToReleaseSelected}
              quantityAbleToDraftSelected={quantityAbleToDraftSelected}
              setSelectedCheckbox={setSelectedCheckbox}
              setCurrentBatchStatus={setCurrentBatchStatus}
              batchRefresh={batchRefresh}
              setBatchWaiter={setBatchWaiter}
            />
          )}
          {currentFolder == undefined &&(
            <Shimmer customElementsGroup={(<div>
              <ShimmerElementsGroup
                flexWrap
                width="100%"
                shimmerElements={[
                  { type: ShimmerElementType.line, width: '50%', height: 20, verticalAlign: 'bottom' },
                  { type: ShimmerElementType.gap, width: '50%', height: 20 },
                  { type: ShimmerElementType.gap, width: '100%', height: 10 },
                  { type: ShimmerElementType.line, width: '90%', height: 20 },
                  { type: ShimmerElementType.line, width: '90%', height: 20 }
                ]}
              />
            </div>)}/>
          )}
          
          <div>
            <input
              id="fileInput"
              type="file"
              onChange={(e) => onChangeFileChooser(e)}
              className={styles.uploadInput}
            />
            {waitWorkSpaceItem || currentFolder == undefined || refresh == true ?(
              <LoaderComp big={true}/>
            ):(

              <ul className={`${styles.ulContentPendecies} ${!boxLayout && styles.gridBorder}`} ref={refHtml}>

                { boxLayout == false && (
                  <WorkSpaceItemListFileTitle
                    orderDesc={orderDesc}
                    setOrderDesc={setOrderDesc}
                    selectedCheckbox={selectedCheckbox}
                    workspaceItems={WorkSpaceItem}
                    chengeAllChackbox={chengeAllChackbox}
                    nameOrganization={nameOrg!}
                    layoutPendencies={false}
                  />
                ) }

                <WorkSpaceItemListFileUpload 
                  currentFolder={currentFolder}
                  newerUploadList={newerUploadItems}
                  returnParamens={returnParamens}
                  boxLayout={false}
                />

                {WorkSpaceItem.map((workSpaceC, index, array) =>   
                    boxLayout ? (
                    <WorkSpaceItemListFileShrink 
                      key={workSpaceC.id}
                      ref={el => myRefs.current[index] = el}
                      workItemProps={returnParamens(index, workSpaceC, currentFolder, true)}
                      isUpload={false}
                    />
                    ):(
                    <WorkSpaceItemListFileComponent
                      key={workSpaceC.id}
                      ref={el => myRefs.current[index] = el}
                      workItemProps={returnParamens(index, workSpaceC, currentFolder, true)}
                      isUpload={false}
                    />
                    )
                )}
              </ul>
            )}
          </div>
          <Snackbar
              open={popupOk}
              autoHideDuration={6000}
              message="Archived"
              onClose={handleClose}
              anchorOrigin={{ vertical:"bottom", horizontal:"center" }}
              sx={{ bottom: { xs: 50, sm: 20 } }}
            >
              <MuiAlert elevation={6} variant="filled" sx={{ width: '100%' }} severity={alertColor} onClose={handleClosePO}>
                {errorMessage}
              </MuiAlert>
          </Snackbar>
          <ChoicerViwerModal
            idItem={itemIdConst!}
            isOpen={isOpenConst}
            setIsOpen={exibirViewerBoolean}
            menuConfig={menuConfigConst}
            name={nameConst!}
            extension={extensionConst!}
            previewWorkspaceListProps={{            
              workspaceItemList:WorkSpaceItem,
              changeModalContent:updateContent,
              getWorkspaceWhenVisible:getWorkspaceWhenVisible,
              workMax:WorkMax
            }}
            idFolder={searshParams.idFolder}
          />
        </div>
      )
  })


  export default WorkSpaceItemListFile;