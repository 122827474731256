import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProfile } from '../../context/ProfileProvider/useProfile';
import { Stack } from '@fluentui/react/lib/Stack';
import styles from './FeedList.module.css';
import "./antd.css";
import FeedListComp from './FeedListComp';
import moment, { Moment } from 'moment';
import { ConfigProvider, DatePicker as DatePickerAnt } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import localeBr from 'antd/es/locale/pt_BR';
import localeUs from 'antd/es/locale/en_US';
import Btn from '../../components/Button/Btn';
import { Locale } from 'antd/es/locale-provider';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePickerAnt;

const FeedList = (props: any) => {
	const profile = useProfile();
	const { t, i18n } = useTranslation();
	const { nameOrg } = useParams();
	const [reload, setReload] = useState<boolean>(false);
	const [isAdminOrganization, setisAdminOrganization] =
		useState<boolean>(false);
	const [selectDateBegin, setSelectDateBegin] = useState<Date | undefined | null>(
		new Date()
	);
	const [selectDateEnd, setSelectDateEnd] = useState<Date | undefined | null>(
		new Date()
	);
	const [localization, setLocalization] = useState<Locale>();

	const onSelectDateBegin = (date: Date | null | undefined) => {
		setSelectDateBegin(date);
		setReload(true);
	};

	const onSelectDateEnd = (date: Date | null | undefined) => {
		setSelectDateEnd(date);
		setReload(true);
	};

	const handleToday = (value: string) => {
		if(value === 'start'){
			if(selectDateEnd){
				if(new Date() > selectDateEnd){
					return
				}
			}
			setSelectDateBegin(new Date())
		} else {
			if(selectDateBegin){
				if(new Date() < selectDateBegin){
					return
				}
			}
			setSelectDateEnd(new Date())
		}
	}
	useEffect(() => {
		if (reload == true) {
			setReload(false);
		}
	}, [reload]);

	useEffect(() => {
		if (i18n.language === 'pt-BR') {
			setLocalization(localeBr);
		} else {
			setLocalization(localeUs);
		}
	}, [i18n.language]);

	useEffect(() => {
		if (profile.organizations != undefined) {
			for (let organization of profile.organizations) {
				if (nameOrg == organization.internalName) {
					if (organization.isAdmin == true) {
						setisAdminOrganization(true);
					} else {
						setisAdminOrganization(false);
					}
				}
			}
		}
	}, [profile]);

	return (
		<div>
			<Stack horizontal className={styles.hPage}>
				<Stack.Item grow>
					<h3 className={styles.hList}>Feed</h3>
				</Stack.Item>
				<Stack className={styles.stackFather}>
					<Stack.Item className={styles.dateLabelContainer}>
						<div className={styles.labelDateBegin}>
							{t('CalendarLabel.From')}
						</div>
						<div className={styles.labelDateEnd}>{t('CalendarLabel.To')}</div>
					</Stack.Item>
					<Stack.Item className={styles.stackSide}>
						<ConfigProvider locale={localization}>
							<RangePicker
								className={styles.rangePickerInput}
								value={[moment(selectDateBegin), moment(selectDateEnd)]}
								onChange={(values) => {
									onSelectDateBegin(values?.[0]?.toDate());
									onSelectDateEnd(values?.[1]?.toDate());
								}}
								separator={<CalendarOutlined style={{ color: '#BFBFBF' }} />}
								format={i18n.language === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
								renderExtraFooter={() => (
									<div className={styles.calendarBtnContainer}>
										<div className={styles.calendarBtnStart}>
											<Btn
												text={t('CalendarButtonToday.TodayButton')}
												onClick={() => handleToday("start")}
											/>
										</div>
										<div className={styles.calendarBtnEnd}>
											<Btn
												text={t('CalendarButtonToday.TodayButton')}
												onClick={() => handleToday("end")}
											/>
										</div>
									</div>
								)}
							/>
						</ConfigProvider>
					</Stack.Item>
				</Stack>
			</Stack>

			{(reload==false && nameOrg != undefined) && (
				<FeedListComp
					nameOrg={nameOrg}
					begin={moment(selectDateBegin).format('MM-DD-YYYY 00:00:00')}
					end={moment(selectDateEnd).format('MM-DD-YYYY 00:00:00')}
				/>
			)}
		</div>
	);
};

export default FeedList;
