export default {
  translations: {
    otpDialog: {
      button: "Remover senha de uso único",
      title: "Tem certeza que deseja remover sua senha de uso único?",
      contentEmail: "E-mail da conta:",
      content: "Saiba que após confirmar, as configurações de autenticação de multifatores de sua conta serão retiradas. E posteriormente deverão ser configuradas novamente.",
      successfullyOtpPass: "Senha de uso único aceita com sucesso.",
      successfullyOtpPassContent: "Sua senha de uso único foi aceita com sucesso. Clique em confirmar para ser redirecionado ao login e acessar a aplicação."
    },
    TwoFa:{
      title:"Foi enviado um código",
      message:"Um código foi enviado para o seu email.",
      button:"Digitar este código"
    },
    Novo: {
      message: 'Novo'
    },
    Nome: {
      message: 'Nome do documento',
      text: 'Nome',
      username: 'Nome do usuário',
      workspace: 'Nome do Workspace',
      group: 'Nome do Grupo',
      validation: 'Nome da Validação',
      organization: 'Nome da Organização'
    },
    D4Sign:{
      Confi: 'Configuração D4 Sign',
      SafeBox: 'Cofre',
      Error: 'D4Sign retornou erro',
      Download: 'Download documento assinado',
      Wait: 'Aguardo, estamos preparando o link'
    },
    Sensibility:{
      word:"Sensibilidade",
      higth:"Alta",
      medium:"Média",
      low:"Baixa"
    },
    Type: {
      message: 'Tipo'
    },
    Extencao: {
      message: 'Extensão'
    },
    Editar: {
      message: 'Editar'
    },
    Excluir: {
      message: 'Excluir'
    },
    Clone: {
      message: 'Clonar'
    },
    Iniciais: {
      message: 'Iniciais'
    },
    NomeInterno: {
      message: 'Nome interno'
    },
    UsuariosAdm: {
      message: 'Usuários administradores'
    },
    ConfigWork: {
      message: 'Configurações do Workspace'
    },
    Salvar: {
      message: 'Salvar'
    },
    Deletar: {
      message: "Deletar"
    },
    Resetar: {
      message: "Resetar"
    },
    SalvarWork: {
      message: 'Salvar Workspace'
    },
    CloneWork: {
      message: 'Clonar Worskpace'
    },
    Cancelar: {
      message: 'Cancelar'
    },
    Fechar: {
      message: 'Fechar'
    },
    WorkSalvo: {
      message: 'Workspace salvo'
    },
    ErroAoSalvar: {
      message: 'Não conseguiu salvar'
    },
    CanNotClone:{
      message: 'Não conseguiu clonar'
    },
    UsuariosSugeridos: {
      message: 'Usuários sugeridos'
    },
    UsuariosNaoEncontradors: {
      message: 'Usuários não encontrados'
    },
    SalvoOK: {
      message: 'Salvo Ok'
    },
    AdmOrg: {
      message: 'Administrador da organização '
    },
    OnlyRead: {
      message: 'Somente leitura'
    },
    MinDeCaracteres:{
      message: 'Nome deve conter 3 ou mais caracteres'
    },
    SalvarUsuario:{
      message:'Salvar Usuário'
    },
    ExluirUsuarioValid:{
      message:'Tem certeza que deseja excluir o usuário'
    },
    UserExcluido:{
      message:'Usuário excluído.',
      error:'Error: User cant deleted.'
    },
    Continuar:{
      message:'Continuar'
    },
    Usuarios:{
      message:'Usuários'
    },
    Org:{
      saveOk:'Organização salva'
    },
    SalvarOrg:{
      message:'Salvar Organização'
    },
    NomeOrg:{
      message:'Nome da Organização'
    },
    NomeWorkspace:{
      message:'Nome do Workspace'
    },
    ExcluidoComSucesso:{
      message:'Excluído com sucesso'
    },
    EditadoComSucesso:{
      message:'Editado com sucesso'
    },
    ConfigOrg:{
      message:'Configurações de Organização'
    },
    ExcluirOrg:{
      message:'Excluir organização'
    },
    CertezaExcluir:{
      message:'Tem certeza que deseja excluir?'
    },
    CertezaExcluirOrganization:{
      message:'Tem certeza que deseja excluir esta Organização?'
    },
    Workspace:{
      permission:"Permissões do Workspace",
      reader:"Leitor",
      writer:"Editor",
      none:"Desativar",
      deleteSucess:"Workspace deletado",
      createSucess:"Workspace criado",
      cloneSucess:"Workspace clonado",
    },
    Item:{
      permission:"Permissões do Item",
      reader:"Leitor",
      writer:"Editor",
      none:"Desativar",
      deleteSucess:"Item deletado",
      deleteProblem:"Item problema na exclusão",
      createSucess:"Item criado",
      folow:"Seguir pai",
      isFolow:"Seguir",
      dontFolow:"Não Seguir",
      deleteBtn:"Deletar ",
      editBtn:"Renomear Item",
      deleteMessage:"Tem certeza que deseja excluir?",
      setDraft:"Draft",
      setRelease:"Release",
      d4SignVersion:"Ver versão assinada",
      undoDraft:"Descartar Draft",
      undo:"Desfazer"
    },
    CertezaExcluirWorkspace:{
      message:'Tem certeza que deseja excluir este Workspace?'
    },
    EscrevaNomeOrg:{
      message:'Escreva o nome da organização'
    },
    NomeIncorreto:{
      message:'Nome incorreto'
    },
    Organizacoes:{
      message:'Organizações'
    },
    GrupoSalvo:{
      message:'Grupo salvo'
    },
    AlterarUsuarios:{
      message:'Alterar usuários'
    },
    SalvarGrupo:{
      message:'Salvar Grupo'
    },
    GrupoExcluido:{
      message:'Grupo excluído.'
    },
    CertezaExcluirGrupo:{
      message:'Tem certeza que deseja excluir o grupo?'
    },
    Adicionar:{
      message:'Adicionar'
    },
    AdicionarUsuario:{
      message:'Adicionar Usuário'
    },
    UsuarioDoGrupo:{
      message:'Usuários do grupo'
    },
    Grupos:{
      message:'Grupos'
    },
    TiposDeDocumento:{
      message:'Tipos de Documento'
    },
    ValidationRule:{
      menu:'Regras de Validação',
      errorFind:'Não encontrada regra de validação',
      saveOk:'Regra de Validação salva',
      error:'Erro no salvar',
      addActivity:"Adicionar Atividade do Workflow",
      backOperationLabel:"Label para Voltar",
      forwardOperationLabel:"Label para Avançar",
      dinamic:"Responsável Dinâmico",
      erroName:"Nome não deve ser vazio",
      erroBackOperationLabel:"Label Voltar não deve ser vazio",
      erroForwardOperationLabel:"Label Avançar não deve ser vazio",
      erroItensTagUser:"Deve ter pelo menos um responsável",
      doYouWantToRemoveTheValidationRuleUsedOnTheseFiles:"Tem certeza que deseja remover esta Validation Rule? Não será mais possível adicionar documentos a ela. Os documentos que já estão neste fluxo seguirão até serem liberados",
      erroTermTypes:"Erro em procurar os tipos de prazo",
    },
    Empity:{
      message:'Vazio'
    },
    Arvore:{
      message:'Árvore'
    },
    Configuracoes:{
      message:'Configurações'
    },
    Expandir:{
      message:'Expandir'
    },
    Diminuir:{
      message:'Diminuir'
    },
    Sair:{
      message:'Sair'
    },
    AlteradoComSucesso:{
      message:'Alterado com sucesso'
    },
    AssinadoComSucesso: {
      message: "Assinado com sucesso"
    },
    FinalizadoComSucesso: {
      "message": "Documento finalizado."
    },
    AlgoErradoAconteceu: {
      message:'Algo errado aconteceu'
    },
    SenhasNaoBatem:{
      message:'Os caracteres inseridos estão incorretos.'
    },
    CodigoNaoEncontrado:{
      message:'Código não encontrado.'
    },
    SemCodigo:{
      message:'Sem código'
    },
    Senha:{
      message:'Senha',
      newPassword: 'Nova senha',
      typeYourPassword: "Digite sua senha...",
      typeYourPasswordAgain: "Digite sua senha novamente...",
      newPasswordRequirements: "A senha deve ter no mínimo 6 caracteres, sendo eles: uma letra maiúscula, um caractere especial e um número."
    },
    ConfirmarSenha:{
      message:'Confirmar nova senha'
    },
    Enviar:{
      message:'Enviar'
    },
    Confirmar:{
      message:'Confirmar'
    },
    IrParaLogin:{
      message:'Retornar para login',
      message2: 'Ir para login'
    },
    Entrar:{
      message:'Entrar',
      forgotPassword:'Esqueceu sua senha',
      codPage:"Ir para a página de código do e-mail",
      characterMinimun: "Mínimo de caracteres",
      specialcharacters: "Um caractere especial",
      oneNumber: "Senha contendo número",
      capitalLetter: "Uma letra maiúscula"
    },
    InsiraEmail:{
      message:'Insira seu e-mail cadastrado'
    },
    InsiraSenha:{
      message:'Insira sua senha cadastrada'
    },
    Code:{
      message:'Código',
      acepted:'Código aceito',
      header:'Código por e-mail'
    },
    AccessCode: {
      message: 'Acesso por código',
      label: 'Código de acesso',
      info:  
      'Você recebeu um código de acesso para acessar a plataforma? Insira este código no campo abaixo.'
    },
    LembrarDeMim:{
      message:'Lembrar de mim'
    },
    EsqueceuSenha:{
      message:'Esqueceu sua senha?'
    },
    UsuarioOuSenhaIncorretors:{
      message:'Usuário ou senha incorretos'
    },
    UsuarioOuCodeIncorretors:{
      message:'Usuário ou código incorretos'
    },
    DoitLogin:{
      message:'Faça o login',
      sucess:"Codigo aceito"
    },
    Upload:{
      done:'Finalizado',
      error:'Ocorreu um erro',
      file:"Documento",
      folder: "Carregar pasta",
      newFolder:"Pasta",
      permission:"Permissões",
      permissionGroup:"Permissões de Grupos",
      permissionUser:"Permissões de Usuários"
    },
    NotFound:{
      message: "Não encontrado"
    },
    Datadamodificacao:{
      message:"Criado em"
    },
    Modificadopor:{
      message:"Modificado por"
    },
    Tamanhodoarquivo:{
      message:"Tamanho"
    },
    Folder:{
      save:"Salvo com sucesso",
      toSave:"Alteração"
    },
    DownloadFileError:{
      message:"Erro no Download"
    },
    ReleaseError:{
      message:"Colocar Release com erro"
    },
    DraftError:{
      message:"Colocar Draft com erro"
    },
    ArcinvingError:{
      message:"Colocar Em Archiving com erro"
    },
    SucessMove:{
      message:"Movido com sucesso",
      error:"Arquivo não foi movido"
    },
    Start:{
      message:"Iniciar"
    },
    Action:{
      message:"Ação",
      send:"Enviar para regra de validação"
    },
    ErrorMove:{
      message:"Erro ao mover!"
    },
    Revisao:{
      term:"Revisões",
      tracker:"Rastreador de Atividades",
      time:"Tempo",
      ipUser:"Ip",
      name:"Nome do usuário",
      message:"Mensagem",
      noEntries:"No Entries"
    },
    DownloadSuccess:{
      message:"Dowload feito com sucesso!"
    },
    DraftDate:{
      message:"Data criação"
    },
    Atualizar:{
      message:"Atualizar"
    },
    Description:{
      message:"Descrição"
    },
    CertezaExcluirDocumentType:{
      message:'Tem certeza que deseja excluir este tipo de documento?'
    },
    DocumenType:{
      deleteSucess:"Tipo de documento deletado!",
    },
    New:{
      female:"Nova",
      male:"Novo"
    },
    ValidationRules:{
      NewActivity:"Nova Atividade",
      Deleted:"Tem certeza que deseja excluir esta regra de validação?",
      DeletedOk:"Regra de validação deletada",
      RemovedOK:"Regra de validação removida"
    },
    Select:{
      message:"Selecione",
      error:"Erro ao procurar parâmetros"
    },
    Responsible:{
      message:"Responsável",
      select:"Selecionar Responsável",
      suggestions:"Responsáveis Sugeridos",
      notFound:"Nenhum responsável encontrado",
      lessOne:"Mínimo um responsável"
    },
    ActivityName:{
      message:"Nome da Atividade"
    },
    Pendencias:{
      message:"Pendências"
    }, 
    CertezaExcluirPermission:{
      message:'Tem certeza que deseja excluir este tipo de permissão?'
    },
    Label:{
      Back:"Label para Voltar",
      Forward:"Label para Avançar"
    },
    CPF:{
      choice:'D4 Sign',
      invalid:'CPF Inválido',
      nextStep:'Validar',
      SaveInServer:'Salvando no servidor'
    },
    foreign:{
      message:'Estrangeiro'
    },
    loadMore:{
      text:"Carregar mais",
      error:"Não encontrado mais regras de validações"
    },
    Exibir:{
      message:"Exibir"
    },
    UsuarioSalvo:{
      message:"Usuário Salvo"
    },
    MaxDeCaracteres:{
      message:'O nome deve ter no máximo 50 caracteres'
    },  
    Copy:{
      message:'Copiado com sucesso!',
      error:'Erro ao copiar!'
    },
    Wait:{
      message:"Aguarde"
    }, 
    ErroMail: "E-mail inválido",
    SharedFile: {
      Error: "Erro a consultar",
      Menu: "Compartilhar arquivo",
      FileDontShared: "Arquivo não compartilhado",
      ToShare: "Compartilhar",
      AddEmail: "Adicionar e-mail",
      EndedByDay: "Compartilhar até",
      EndedByAcces: "Compartilhar até chegar a quantos acessos",
      WithoutEnded: "Não expira",
      ErroOnDelete: "Aconteceu um erro ao deletar",
      ErroOnSave: "Aconteceu um erro ao salvar",
      NotCheckBox:"É necessário marcar uma caixa de seleção",
      ListEmails: "Lista deve ter e-mails",
      HasWatermark: "Compartilhar com marca d'água"
    },
    TextField:{
      TooltipTitle: "Clique no botão para adicionar e-mail."
    },
    permissionDriveOrg:{
        message:"Atribuir permissão para o drive",
        permission:"Permissão drive"
    },
    renameItem:{
      message:"Renomear ",
      error:"Erro ao renomear"
    },
    editItem:{
      message:"Editar "
    },
    owner:{
      message:"Proprietário da pasta"
    },
    permissionFolder:{
      message:"Permissões"
    },
    Visualizador:{
      message:"Visualizador"
    },
    PermicoesItem:{
      message:"Permissões do item",
    },
    Move:{
      moveBy: "Mover para",
      message:"Mover ",
      to:" para ",
      toRoot:"Mover para raiz",
      root:" para raiz",
    },
    AcaoExecutada:{
      message:"Ação Executada"
    },
    RevisaoDocument:{
      message:"Revisão do Documento"
    },
    Usuario:{
      message:"Usuário"
    },
    DateAction:{
      message:"Data da ação"
    },
    ErrorDate:{
      Begin:"Data inicial não pode ser maior que a final.",
      End:"Data final não pode ser menor que a inicial."
    },
    NewResp: {
      message: "Selecione novo responsável",
    },
    ArqResp: {
      message: "Arquivos que o usuário é responsável.",
    },
    SelectResp: {
      message: "Selecione o novo usuário para a regra de validação.",
    },
    Organizador: {
      message: "Organizador",
    },
    CalendarLabel: {
      message: "Vencimento",
      toolTipMessage:"O vencimento define o tempo de validade de um documento.",
      From: "De:",
      To: "Até:",
    },
    CalendarButtonToday: {
      TodayButton: "Hoje",
    },
    ReturnEmptyTracker: {
      MessageSnack: "Não encontrado o Tracker",
    },
    NameExist:{
      message:"Nome já existe!"
    },
    Rejeitado:{
      message:"Rejeitado com sucesso!"
    },
    Arquivar:{
      message:"Arquivar"
    },
    ResetPasswordRequest:{
      message:"Redefinir senha"
    },
    InvalidEmail:{
      message:"Email inválido"
    },
    EmailSent:{
      message:"Email enviado"
    },
      StroageInformation:{
        bookcase:"Estante",
        shelf:"Prateleira",
        position:"Posição / Caixa",
        menu:"Localização Física",
      },
    Delete:{
      message:"Excluir"
    },
    Remove:{
      message:"Remover"
    },
    SelectValidationRule:{
      message:"Selecione a Validation rule"
    },
    NotificationEmail:{
      message:"Receber notificação por e-mail"
    },
    ActionsInBatch:{
      message:"Ações em lote"
    },
    FilesWentToRelease:{
      message:"Arquivos foram para Release"
    },
    ErrorWhenUpdatingToRelease:{
      message:"Erro ao atualizar para release"
    },
    FilesWentToDraft:{
      message:"Arquivos foram para Draft"
    },
    ErrorWhenUpdatingToDraft:{
      message:"Erro ao atualizar para Draft"
    },
    UploadAsRelease:{
      message:"Upload como Release"
    },
    OrganizerCannotDelete:{
      message:"Organizador não pode excluir"
    },
    EditorCannotDelete:{
      message:"Editor não pode excluir"
    },
    "EditorCannotSeeRevision": {
      message:"Editor não verá revisões"
    },
    Undo:{
      message:"Desfazer"
    },
    ConfirmTheDiscardOfTheDraft:{
      message:"Essa ação é irreversível! Confirma o descarte do Draft?"
    },
    Term: {
      message:"Prazo",
      days:"Dias",
      select:"Selecione um Prazo",
      addTerm:"Adicionar tipo de prazo",
      current: "Prazo limite",
      on: "Prazo positivo",
      out: "Prazo vencido",
      noDeadline: "Sem prazo"
    },
    FileDownload: {
      message:"Download do arquivo"
    },
    NoViewerModal: {
      title: "Não é possível visualizar este arquivo",
      content: "O arquivo que você está tentando visualizar não possui visualização para leitura. Para conseguir visualizá-lo, clique no botão abaixo para fazer download e acesse o arquivo."
    },
    WhatAreYouLookingFor: {
      message: "O que você está procurando..."
    },
    informationModal:{
      message:"Informação para quem foi enviada a Validation"
    },
    termRule:{
      message:"Escolha um prazo para a regra de validação.",
      select:"Selecione um Prazo"
    },
    tag:{
      editMessage:"Tag editada com sucesso!",
      addMessage:"Tag adicionada com sucesso!",
      errorEdit:"Erro ao editar tag",
      errorAdd:"Erro ao adicionar tag",
      notFound:"Tags não encontradas",
      new:"Nova Tag",
      suggested:"Tags Sugeridas",
      edit:"Editar Tags",
      add:"Adicionar Tags",
      insertMessage:"Insira tags em arquivos e pastas para facilitar o acesso e encontrar facilmente o que procura.",
      LoadingResults:"Carregar mais resultados...",
      save:"Salvar Informações",
      cancel:"Cancelar",
      nameFile:"Nome de arquivo",
      descFile:"Descrição",
      semDesc:"Sem descrição",
      fileTag:"Tags do arquivo",
      tagExist:"Nome já existe",
      messageTag:"Tag já existe"
    },
    validationRulePanel: {
      select: "Selecione a regra de validação que está de acordo com a necessidade atual do documento.",
      execution: "Execução",
      selectUser: "Selecione o responsável que irá executar a regra de validação selecionada acima.",
      alreadySetConfig: "Configurações já colocadas na validation rule.",
      alreadySetValidation: 'As configurações da validação já foram alocadas na tarefa. Clique em "Enviar para responsável" para continuar.',
      sendToResponsible: "Enviar para responsável",
      OkUnderstand: "Certo, entendi.",
      validationRuleSubmit: "Validation Rule enviada com sucesso",
      validationRuleData: "A regra de validação foi enviada para o responsável que corresponde com os dados listados abaixo.",
      responsible: "Responsável",
      withTerm: "Sem prazo.",
      emailWrong: "O item que estava pendente foi concluído com sucesso. Você pode desconsiderar o e-mail recebido e fechar esta janela.",
      responsableError: "Responsável deve ser definido.",
      norVrFounded: "Nenhuma regra de validação encontrada.",
      title: "Regra de validação"
    },
    substituir:{
      message:"Substituir"
    },
    DetailDocumentPage: {
      Actions: "Ações",
      Save: "Salvar",
      Description: "Sem descrição...",
      ModDate: "Data de modificação",
      ModResp: "Responsável  do documento",
      Term: "Prazo",
      NoTerm: "Sem prazo...",
      Visualization: "Visualização",
      ActivityTracker: "Rastreador de atividades",
      Location: "Localização Física",
      Reviews: "Revisões"
    },
    Wizard: {
      organization: "Organização",
      password: "Senha",
      confirmPassword: "Confirme sua senha",
      welcome: "Bem vindo(a)!",
      return: "Retornar",
      continue: "Avançar",
      conclude: "Concluir cadastro",
      name: "Nome",
      addUsers: "Adicionar",
      errorUsers: "Número de usuários inválidos",
      emailInfo:"Adicionar até 5 colaboradores para fazer parte do ambiente de trabalho da empresa",
      descriptionEnd:"Verifique se as informações cadastradas abaixo estão corretas. Se sim, clique em \"",
      descriptionEndBold:"Concluir Cadastro",
      descriptionEndSecond:"\".",
      maxUserlength: "Máximo cinco usuários",
      fillAllFields: "Preencha corretamente.",
      emailAlreadyInList: "E-mail já está na lista."
    },
    Login: {
      forgotPass: "Esqueci minha senha",
      password: "Senha",
      goToEmailPage: "Ir para página de código do e-mail",
      accessAcount: "Acessar Conta",
      helloAgain: "Olá, novamente! Preencha os campos abaixo com os seus dados para acessar a sua conta.",
      putYourNewPass: "Insira a nova senha",
      fillTheFieldsDown: "Preencha os campos abaixo com a sua nova senha.",
      forgotYourPass: "Esqueceu sua senha?",
      fillTheEmailRegistered: "Preencha o campo abaixo com o seu e-mail cadastrado para recuperar a sua senha.",
      typeYourEmail: "Digite o seu e-mail...",
      typeYourPassword: "Digite a sua senha...",
      accessWithCode: "Acesso por código",
      doYouReceived: "Você recebeu um código de acesso para acessar a plataforma? Insira este código no campo abaixo.",
      yourAccessCode: "Código de acesso.",
      typeYourAccessCode: "Digite o código de acesso...",
      privacyPolicy: "Política de Privacidade",
      allRights: "Todos os direitos reservados",
      checkYourEmail: "Confira o seu e-mail",
      confirmationEmail: "O e-mail de confirmação e processo de alteração de senha foi enviado para o seu e-mail.",
      didntRecieved: "Você não recebeu o e-mail?",
      clickHereToResend: "Clique aqui para reenviar.",
      alrightUnderstand: "Certo, entendi",
      qrcodeTitle: "QR code gerado",
      qrcodeSub: "Escaneie o qrcode abaixo para configurar a sua aplicação.",
      otpTittle: "Senha uso único.",
      otpSub: "Coloque o código já obtido em seu autenticador.",
      OtpMessage: "Senha de uso único.",
      qrcodeRedirect: "Após escanear o qrcode, clique no botão para inserir sua senha de uso único.",
      oneUsePassword: "Colocar a senha de uso único.",
      otpErrorMessage: "Senha de uso único necessária",
      error: "Problema no login",
      oneTimePasswordError: "A sua senha de uso único não foi aceita, tente novamente." ,
      oneTimePasswordSucess: "Sua senha de uso único foi aceita com sucesso.",
      microsoft: "Continue com",
      confirm: "Confirmar"
    },
    InvalidCaptcha:{
      message:"Captcha inválido"
    },
    Reset:{
      message:"Resetado com sucesso.",
    },
    ResetPasswordSuccess: {
      title: "Senha alterada com sucesso",
      subtitle: "A sua senha foi alterada com sucesso, lembre-se de guarda-la a sete chaves. Clique no botão abaixo para acessar o login."
    },
    AutoArquivar:{
      message:"Auto Arquivar"
    },
    ParaArquivar:{
      message:"Para arquivar"
    },
    Temporality:{
      message:"Temporalidade",
      panelTitle:"Adicionar Temporalidade",
      dateTemp:"Auto Arquivar em:",
      select:"Selecionar Temporalidade",
      descriptionTemporalityMenu:"Selecione um dos workspaces abaixo para visualizar o relatório de temporalidade."
    },
    TemporalityComboBox:{
      ExplanatoryText:"Alterar status para",
      TooltipHostDefalt:"O status define o estado, posicionamento ou situação do documento no processo em que se encontra.",
      TooltipHostExpired:'O status "Vencido" irá definir que o prazo acabou para executar alguma ação relacionada com o documento selecionado.',
      TooltipHostArquived:'O status "Arquivado" irá guardar e inativar o documento. O mesmo pode ser ativo e retomado novamente a qualquer momento.',
      TooltipHostDraft:'O status "Draft" é definido como rascunho, ou seja, o item se encontrará em estado inicial e precisará passar por uma nova validação.',
      PlaceHolder:"Selecione"
    },
    dataArqchive:{
      message:"Data de Arquivamento"
    },
    WithoutRule: {
      withoutRule: "Sem regra.",
      withoutDesc: "Sem descrição."
    },
    ViwerInfo: {
      cannotView: "Não é possível visualizar este arquivo",
      notPreview: "O arquivo que você está tentando visualizar não possui visualização para leitura. Para conseguir visualizá-lo, clique no botão abaixo para fazer download e acesse o arquivo.",
      downloadFile: "Download do arquivo"
    },
    DataTableHeaders: {
      Reviews: "Revisões",
      Actions: "Ações",
      Users: "Usuários",
      Date: "Vencimento",
      Name: "Nome",
      Description: "Descrição",
      ExplanatoryText: "Data em que a ação será executada",
      TooltipHost:"O vencimento define o tempo de validade de um documento.",
    },
    FilterTables: {
      OrdenationDate: "Ordem data",
      Reviews: "Revisões",
      AlphaOrder: "Ordem alfabética",
      OrderByUsers: "Ordenar por usuários",
      OrderByMessages: "Ordenar por mensagens",
      Descending: "Decrescente",
      Ascending: "Crescente",
      apply: "Aplicar",
      clearFilters: "Limpar filtros"
    },
    DetailPhLocation: {
      TypeHereLocal: 'Digite aqui... (Ex.: Sala, Prédio, Andar)',
      TypeHereBookcase: 'Digite aqui... (Ex.: Estante verde, Estante n° 3)',
      TypeHereShelf: 'Digite aqui... (Ex.: Prateleira n° 3, Quarta prateleira de cima para baixo)',
      TypeHerePosition: 'Digite aqui... (Ex.: Caixa vermelha, Arquivo em cima da mesa)',
      SaveButton: "Salvar alterações",
      CancelButton: "Cancelar alterações"
    },
    UnableToReturnItemTemporality: {
      message:"Incapaz de devolver a Temporalidade do Item"
    },
    SelectTemporality: {
      message:"Selecionar Temporalidade"
    },
    SelectDocumentExpiration: {
      message:"Selecionar Validade do Documento"
    },
    DocumentValidity: {
      message:"Validade do documento"
    },
    Validity: {
      message:"Validade"
    },
    EmptyTemporalityField:{
      message: 'Campo de temporalidade vazio'
    },
    EmptyDocumentValidityField:{
      message: 'Campo de validade do documento vazio'
    },
    RetroactiveDueDate:{
      message: 'Vencimento retroativo'
    },
    HasExpired:{
      message: 'Expirou'
    },
    Option:{
      message: "Definir status como"
    },
    RemoveTemporality:{
      message: "Remover temporalidade"
    },
    Reload: {
      message: "Recarregar"
    },
    AssignServiceUser: {
      message: "Atribuir usuário de serviço"
    },
    ErrorReceivingAdminUsers: {
      message: "Erro ao receber Usuários administradores"
    },
    Dashboard: {
      storageLimit: "O seu limite de armazenamento é de",
      generalDataUsage: "Uso de dados geral",
      storageInUse: "Espaço em uso",
      freeStorage: "Espaço disponível",
      wsDataUsage: "Uso de dados em workspaces",
      storageUseWithoutContract:  "Sem máximo cadastrado",
      OrganizationDoesntHaveWorkspace: "Organização não há nenhum Workspace."
    },
    Dwg:{
      size:"Tamanho",
      palette:"Paleta",
      orientation:"Orientação",
      converterType:"Tipo de conversor",
      backgroundColor:"Cor de fundo",
      title:"Selecione as opções abaixo para vizualizar o arquivo"
    },
    Selecionar:{
      message:"Selecionar"
    },
    YourBrowserDoesNotSupportThePrintingFunction: {
      message: "Seu navegador não suporta a função de impressão."
    },
    ChooseColumns: {
      message: 'Selecione as colunas',
      description: 'Descrição',
      validationRule: 'Regra de Validação',
      term: 'Prazo',
      size: 'Tamanho',
      createdAt: 'Criado em'

    },
    FilterBy: {
      message: "Filtrar por status",
      toolTipMessage:"O status define o estado, posicionamento ou situação do documento no processo em que se encontra.",
      placeholderMessage:"Selecione..."
    },
    NoRegistry: {
      title:"Nenhum filtro selecionado",
      message: "Selecione um vencimento e um status para realizar a sua busca."
    },
    WorkspaceItemFlowListing:{
      responsible: "Responsável",
      date: "Data",
      progress: "Em andamento",
      waiting: "Aguardando...",
      by: "Por",
      dynamic: "Responsavel dinâmico"
    },
    OrganizationMessages: {
      "problemWithResources": "Problema ao recuperar recursos.",
      "resourceAlert": "Contrato não possui recurso de chamada de Api."
    },
    ErrorViwer:{
      title:"Não foi possível visualizar este arquivo",
      description:"Ocorreu algum erro inesperado na tentativa de visualização. Tente novamente visualizar o arquivo."
    },
    ViwerAutomaticDwg:{
      message:"Gerar dados de visualização de cad em background"
    },
    DuplicateFolder: {
      message:"Duplicar pasta"
    },
    NameMustNotIncludeEitherSlashes: {
      message: "Nome não deve incluir \\ ou /"
    },
    MaintainPermissions: {
      message:"Manter permissões"
    },
    DuplicationOf: {
      message:"Duplicação de"
    },
    CopyDocumentLink: {
      message:"Copiar link do documento",
    },
    
    ItemIdNotFound: {
      message: "Id do item não encontrado",
    },
    WithoutPermission: {
      message: "Sem permissão",
    },
    LoggedHome: {
      QuickAccessContent: "Salve os seus caminhos e arquivos que você utiliza diariamente e consiga acessá-los rapidamente por aqui ou de onde você estiver.",
      QuickAccessTitle: "Acesso rápido",
      OrganizationsContent: 'Os workspaces mais visitados ou favoritados estão disponíveis logo abaixo. Para visualizar todos, clique em "Workspaces".',
      OrganizationsTitle: "Organizações",
      RecentActivities: "Atividade recentes",
      RecentActivitiesContent: 'Tenha acesso às atividades realizadas recentemente. Para visualizar todas, clique em "Atividades recentes".',
      FileName: "Nome do arquivo",
      Action: "Ação",
      Responsible: "Responsável",
      LastModification: "Última modificação",
      favoriteSuccess: "favoritado com sucesso.",
      favoriteExclude: "Favorito deletado.",
      favoriteError: "favorite já existente.",
      NoRecentActivities: "Não há atividade recentes",
      MovingOrganization: "Comece a movimentar a sua organização por meio dos workspaces. Com isso, você irá visualizar as atividades por aqui."
    },
    Minicard: {
      File: "Arquivo",
      Folder: "Pasta",
      Desfav: "Desfavoritar",
      NothingViewMiniCardTitle: "Não há itens salvos",
      NothingViewMiniCardContent: "Nenhum item foi salvo até o momento.",
    },
    LogErrors:{
      browser: "Navegador",
      errorFound: "Erro Encontrado",
      createdDate: "Data de Criação",
      server: "Servidor",
      method: "Método",
      stackTrace: "Rastreamento de Pilha",
      userMail: "Email do Usuário",
      line: "Linha",
      search: "Buscar..."
    },
    FileNotFound: {
      message: "Arquivo não encontrado"
    },
    Divider: {
      message: "ou"
    },
    Navbar: {
      placeholderImputText: "O que você está procurando...",
      labelCheckbox: "Somente nesta pasta"
    },
    Drag: {
      NotPossible: "Não é possível arrastar os itens enquanto estiver editando"
    }
  }
}
  
