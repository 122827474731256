import { Box } from '@mui/material';
import React, { useState } from "react";
import { WorkSpaceUserName } from '../../../../../../../../../../model/ItemModel';
import { TagName } from '../../../../../../../../../../model/Tags';
import Tags from '../../../../../../../../../WorkSpaceItem/Tags';
import { NameField } from '../../../../../../../../../WorkSpaceItem/WorkSpaceItemListFileCompose/Components/NameField/NameField';
import TagsField from "../../../../../../../../../WorkSpaceItem/WorkSpaceItemListFileCompose/TagsField";


interface IDocumentDetailMetaDataInformationName {
    workItem: WorkSpaceUserName;
    tagInternal: TagName[];
    onClickTag(): void;
    itemType: string | undefined;
    boxLayout: boolean;
    workspaceId: string;
    idMongo: string;
    setPopupError: (set: boolean, message: string) => void;
    setPopupOk: (set: boolean, message: string) => void;
    openPanelTag: () => void;
    isHover: boolean;
    isValidateReadViwer: boolean;
    isValidateViwer: boolean;
    leave: () => void;
    onDragEnterAndOver: (event: React.DragEvent<HTMLDivElement>) => void;
    onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
    progress: number;
    setFolderExternal: (itemId: string, itemName: string | undefined) => void;
    unvalidType: boolean;
    updateTagName: (index:number, tagName: TagName[], isUpload: boolean) => void;
    indexOfArray: number;
    setTagInternal: (tagName: TagName[] | undefined) => void;
    updateNameDescriptionByIndex:(index:number, name:string, isUpload: boolean, description:string) => void;
    isUpload: boolean;
}

export default function DocumentDetailMetaDataInformationName({
    workItem,
    boxLayout,
    itemType,
    onClickTag,
    tagInternal,
    idMongo,
    openPanelTag,
    setPopupError,
    setPopupOk,
    workspaceId,
    isHover,
    isValidateReadViwer,
    isValidateViwer,
    leave,
    onDragEnterAndOver,
    onDragLeave,
    progress,
    setFolderExternal,
    unvalidType,
    updateTagName,
    indexOfArray,
    setTagInternal,
    updateNameDescriptionByIndex,
    isUpload
}: IDocumentDetailMetaDataInformationName) {

    const [isEditeName, setIsEditedName] = useState<boolean>(false);

    return (
        <Box sx={{
            display: 'flex'
        }}>
            <Box sx={{maxWidth:"600px"}}>
            <NameField
                extension={workItem.extension!}
                id={workItem.id}
                isHover={isHover}
                isValidateReadViwer={isValidateReadViwer}
                isValidateViwer={isValidateViwer}
                itemType={workItem.itemType!}
                leave={leave}
                nameFile={workItem.name}
                onDragEnterAndOver={onDragEnterAndOver}
                onDragLeave={onDragLeave}
                progress={progress}
                setFolderExternal={setFolderExternal}
                unvalidType={unvalidType}
                boxLayout={boxLayout}
                updateNameDescriptionByIndex={updateNameDescriptionByIndex}
                indexOfArray={indexOfArray}
                setPopupError={setPopupError}
                setPopupOk={setPopupOk}
                description={workItem.description}
                isDetailPage
                isUpload={isUpload}
                setIsEditedName={setIsEditedName}
                setIsEditingItem={() => {}}
            />
            </Box>
            <Box sx={{
                paddingLeft: '20px',
                display: 'flex',
                alignItems: 'center'
            }}>
                <TagsField 
                    boxLayout={boxLayout}
                    itemType={itemType!}
                    onClickTag={onClickTag}
                    tagInternal={tagInternal}
                />
                <Tags
                    idMongo={idMongo}
                    idWorkspace={workspaceId}
                    setPopupError={setPopupError}
                    setPopupOk={setPopupOk}
                    tags={tagInternal!}
                    dismissPanel={() => {}}
                    isOpen={false}
                    name={workItem.name != undefined ? workItem.name : ""}
                    descricao={workItem.description != undefined ? workItem.description : ""}
                    updateTagName={updateTagName}
                    indexOfArray={indexOfArray}
                    setTagInternal={setTagInternal}
                    isUpload={isUpload}
                />
            </Box>
        </Box>
    )
}